import { Link } from "gatsby"
import React from "react"
import { PagingProps } from "../contracts/paging"

export default function Paging(props: PagingProps) {
  return (
    <nav className="my-5 paging">
      <ul>
        <li>
          {props.previous && (
            <Link to={`${props.previous.slug}`} rel="prev">
              <svg width="24" height="24" viewBox="0 0 16 16">
                <path
                  d="M9 4 L5 8 L9 12"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                />
              </svg>
              <span className="mx-2">{props.previous.title}</span>
            </Link>
          )}
        </li>
        <li>
          {props.next && (
            <Link to={`${props.next.slug}`} rel="next">
              <span className="mx-2">{props.next.title}</span>
              <svg width="24" height="24" viewBox="0 0 16 16">
                <path
                  d="M7 4 L11 8 L7 12"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                />
              </svg>
            </Link>
          )}
        </li>
      </ul>
    </nav>
  )
}
