import * as React from "react"
import { Link, graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  DataPropsTemplate,
  DataPropsTemplateGeneric,
} from "../contracts/data-props-template"
import { MarkdownRemark } from "../contracts/markdown-remark"
import Paging from "../components/paging"

interface OgloszeniaMarkdownRemark extends MarkdownRemark {
  frontmatter: {
    title: string
    description?: string
    date?: string
    sunday?: string
    layout: string
  }
}

const OgloszeniaTemplate: React.FC<
  PageProps<DataPropsTemplateGeneric<OgloszeniaMarkdownRemark>>
> = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || "Tytuł"
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.sunday || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.sunday}</h1>
          <p>{post.frontmatter.date}</p>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
      </article>
      <Paging
        previous={
          previous
            ? {
                slug: previous.fields.slug,
                title:
                  previous.frontmatter.sunday ||
                  previous.frontmatter.date ||
                  "Poprzednie ogłoszenia",
              }
            : undefined
        }
        next={
          next
            ? {
                slug: next.fields.slug,
                title:
                  next.frontmatter.sunday ||
                  next.frontmatter.date ||
                  "Następne ogłoszenia",
              }
            : undefined
        }
      />
    </Layout>
  )
}

export default OgloszeniaTemplate

export const pageQuery = graphql`
  query OgloszeniaBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "pl")
        sunday
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        sunday
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        sunday
      }
    }
  }
`
